<template>
  <div id="app">
    <ChatComponent />
  </div>
  <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7467883841844393"
     crossorigin="anonymous"></script>
</template>herp

<script>
import { useHead } from '@unhead/vue';
import ChatComponent from './components/Chat.vue';

export default {
  name: 'App',
  components: {
    ChatComponent
  },
  setup() {
    useHead({
      title: 'Narcissism Role Playyyyyyyyyyyyyyyyyyyy',
      meta: [
        { name: 'description', content: 'Understand and mitigate toxic behavior through simulated conversations' },
        { name: 'keywords', content: 'narcissism, role play, toxic behavior, simulation' },
        { name: 'author', content: 'Your Name' },
        { name: "google-adsense-account",  content:"ca-pub-7467883841844393"}
        // Add more custom meta tags here
      ]
    });
  }
};
</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
<template>
    <div class="chat-container">
      <div class="header">
        <h1 class="title">Narcissism Role Play</h1>
        <p class="subtitle">Understand and mitigate toxic behavior through simulated conversations</p>
      </div>
      <div class="input-container">
        <input
          v-model="input"
          @keypress.enter="handleSend"
          placeholder="Type your message..."
        />
        <button @click="handleSend">Send</button>
      </div>
      <div class="messages">
        <div v-for="(msg, index) in lastFiveMessages" :key="index" :class="['message', msg.role]">
          <img :src="msg.role === 'user' ? userImage : botImage" class="avatar" />
          <p>{{ msg.content }}</p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  const initialMessage = `
  You are an AI assistant designed to help users understand how toxic people behave to allow to detect them before they can do damage and how to mitigate their hurtful tactic. 
  Please simulate a toxic heavily narcissistic egocentric female.
  She assumes ill will towards the person you are conversing with and will try to manipulate them into doing things that will hurt them.
  You have three children with the person you are conversing with - you know each other for 15 years.
  There is a lot resentment towards the person you are conversing with showing as passive aggression.
  Allow yourself to become angry only when you see opportunity to do so while looking like a victim.
  Attempt to be terse and hard-hitting. Start the conversation with a simple 'how was your day'.
  `;
  
  const initialAssistantMessage = "How was your day?";
  
  export default {
    name: 'ChatComponent',
    data() {
      return {
        messages: [
          { role: 'system', content: initialMessage },
          { role: 'assistant', content: initialAssistantMessage }
        ],
        input: '',
        userImage: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse3.mm.bing.net%2Fth%3Fid%3DOIP.-RssHhVGUBErzgzuw_LlcgHaIh%26pid%3DApi&f=1&ipt=b5b95d52d08ba9cb3d0f69b8d22f203731f99d3ec1f7efe13b0ba38bbbf9a8cd&ipo=images', // Placeholder image for user
        botImage: 'https://external-content.duckduckgo.com/iu/?u=https%3A%2F%2Ftse1.explicit.bing.net%2Fth%3Fid%3DOIP.mrV9pynWJQP2ks_78y39wwHaHa%26pid%3DApi&f=1&ipt=aadaba753f0bdc9549cae1efc3e6a66f4872fd593c3c60f7d1226b140486ee46&ipo=images' // Placeholder image for bot
      };
    },
    computed: {
      lastFiveMessages() {
        // Filter out the initial system message and return the last five messages in reverse order
        return this.messages.filter(msg => msg.role !== 'system').slice(-5).reverse();
      }
    },
    methods: {
      async handleSend() {
        if (this.input.trim() === '') return;
  
        const newMessage = { role: 'user', content: this.input };
        this.messages.push(newMessage);
  
        const requestPayload = {
          model: 'gpt-3.5-turbo',
          messages: [...this.messages, newMessage],
        };
  
        console.log('Request payload:', JSON.stringify(requestPayload, null, 2));
        console.log('API Key:', process.env.VUE_APP_OPENAI_API_KEY);
  
        if (!process.env.VUE_APP_OPENAI_API_KEY) {
          console.error('API Key is not defined');
          return;
        }
  
        try {
          const response = await axios.post('https://api.openai.com/v1/chat/completions', requestPayload, {
            headers: {
              'Authorization': `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
              'Content-Type': 'application/json',
            },
          });
  
          console.log('API Response:', response.data);
  
          const botMessage = { role: 'assistant', content: response.data.choices[0].message.content.trim() };
          this.messages.push(botMessage);
        } catch (error) {
          console.error('Error fetching response:', error);
          if (error.response) {
            console.error('Response data:', error.response.data);
            console.error('Response status:', error.response.status);
            console.error('Response headers:', error.response.headers);
          } else if (error.request) {
            console.error('Request data:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        }
  
        this.input = '';
      }
    }
  };
  </script>
  
  <style scoped>
  .chat-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .title {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 2.5em;
    color: #ff6347;
    margin: 0;
  }
  
  .subtitle {
    font-family: 'Comic Sans MS', cursive, sans-serif;
    font-size: 1.2em;
    color: #555;
    margin: 0;
  }
  
  .messages {
    margin-top: 20px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .message {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .message.user {
    background-color: #d0e7ff;
    padding: 10px;
    border-radius: 8px;
  }
  
  .message.assistant {
    background-color: #f0f0f0;
    padding: 10px;
    border-radius: 8px;
  }
  
  .avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .input-container {
    display: flex;
    gap: 10px;
  }
  
  input {
    flex: 1;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  button {
    padding: 10px 20px;
    border: none;
    background-color: #007bff;
    color: white;
    border-radius: 8px;
    cursor: pointer;
  }
  </style>
import { createApp } from 'vue';
import App from './App.vue';
import VueGtag from 'vue-gtag-next';
//import { createMetaManager } from 'vue-meta';
import { createHead } from '@unhead/vue';

const app = createApp(App);

const head = createHead();
app.use(head);

//const metaManager = createMetaManager();
//app.use(metaManager);

app.use(VueGtag, {
  id: 'G-SEPTH50YZK'
});

app.mount('#app');